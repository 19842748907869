<template>
  <section class="section main-section">
    <h4 class="section__title section__title_centered">Пользователи Wipon</h4>
    <GoBackIcon back-route-name="main.home" class="mb-1" />
    <q-form @submit.prevent="addQuery" class="row align-center q-gutter-md">
      <q-input
        filled
        v-model="phoneNumber"
        label="Номер телефона"
        hint="Введите номер пользователя"
        lazy-rules
      />

      <q-input
        filled
        v-model="ledgerCode"
        label="Лицевой счет"
        hint="Введите лицевой счет"
        mask="### ### ####"
      />

      <q-input
        filled
        v-model="bin"
        label="ИИН/БИН"
        hint="Введите БИН"
        mask="### ### ### ###"
      />

      <q-input
        filled
        v-model="license"
        label="Номер лицензии"
        hint="Введите номер лицензии"
        type="number"
      />

      <div>
        <q-btn
          label="Поиск"
          type="submit"
          color="primary"
          :loading="isLoading"
        />
      </div>
    </q-form>
    <q-table
      v-show="formattedUsersData.length"
      :loading="isLoading"
      :columns="columns"
      :rows="formattedUsersData"
      :pagination="pagination"
      selection="single"
      v-model:selected="selected"
      hide-pagination
      row-key="id"
      hide-no-data
      style="width: 100%"
      class="q-mt-lg"
    >
      <template v-slot:body-cell-action="props">
        <q-tr :props="props">
          <q-td key="action" :props="props" class="full-width">
            <q-btn color="grey-7" round flat icon="more_vert">
              <q-menu cover auto-close>
                <q-list>
                  <q-item clickable @click="goToUserDetails(props.row.id)">
                    <q-item-section>Детали</q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    @click="setSelectedUserAndShowActivationModal(props.row.id)"
                  >
                    <q-item-section>Активировать тариф</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn></q-td
          >
        </q-tr>
      </template>
    </q-table>
  </section>

  <q-dialog v-model="isActivationModalVisible">
    <q-card>
      <div class="q-pa-md" style="max-width: 400px; min-width: 400px">
        <q-form @submit="activateRate" class="q-gutter-md">
          <q-select
            filled
            v-model="pickedRateForActivation"
            :options="ratesList"
            label="Выберите тариф для активации"
            lazy-rules
            :rules="[(val) => val || 'Поле обязательное']"
          />
          <div class="q-mt-lg">
            <q-btn
              label="Активировать"
              type="submit"
              color="primary"
              :loading="isLoading"
            />
          </div>
        </q-form>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import GoBackIcon from "@/components/icon-components/GoBackIcon.vue";
import usersApi from "@/api/user.api";
import { useRoute, useRouter } from "vue-router";
import { useQuasar } from "quasar";
import subscriptionsApi from "@/api/subscriptions.api";
import {
  DESKTOP_PRICE,
  DESKTOP_TYPE,
  MOBILE_PRICE,
  MOBILE_TYPE,
  TSD_PRICE,
  TSD_TYPE,
} from "@/config/rates.config";

const LAST_OPENED_USER_PHONE = "LAST_OPENED_USER_PHONE";

export default defineComponent({
  name: "WiponUsersPage",
  components: {
    GoBackIcon,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const $q = useQuasar();
    const isLoading = ref(false);

    const phoneNumber = ref("");
    const bin = ref("");
    const ledgerCode = ref("");
    const license = ref("");

    const selectedUserId = ref(null);
    const pickedRateForActivation = ref(null);
    const isActivationModalVisible = ref(false);

    const ratesList = ref([
      { id: 1, type: MOBILE_TYPE, price: MOBILE_PRICE, label: "Смартфон" },
      { id: 2, type: DESKTOP_TYPE, price: DESKTOP_PRICE, label: "Десктоп" },
      { id: 3, type: TSD_TYPE, price: TSD_PRICE, label: "ТСД" },
    ]);

    const foundUsers = ref([]);
    const formattedUsersData = computed(() => {
      return foundUsers.value.map((el) => {
        return {
          phone_number: el.phone_number,
          code: el.ledger?.code,
          buisness_bin: el.store?.buisness_bin,
          buisness_full_legal_name: el.store?.buisness_full_legal_name,
          address: el.store?.buisness_store_address,
          dgd: el.store?.dgd?.name_ru,
          ugd: el.store?.ugd?.name_ru,
          id: el.id,
          style: "color: red",
        };
      });
    });

    const pagination = ref({
      rowsPerPage: 0,
    });

    const selected = ref([]);

    const columns = [
      {
        name: "phone_number",
        required: true,
        label: "Номер телефона",
        align: "left",
        field: (row) => row.phone_number,
        format: (val) => `${val}`,
      },
      {
        name: "code",
        align: "center",
        label: "Лицевой счет",
        field: "code",
      },
      {
        name: "buisness_bin",
        align: "center",
        label: "БИН",
        field: "buisness_bin",
      },
      {
        name: "buisness_full_legal_name",
        align: "center",
        label: "Название организации",
        field: "buisness_full_legal_name",
      },
      {
        name: "address",
        align: "center",
        label: "Адрес",
        field: "address",
      },
      {
        name: "dgd",
        align: "center",
        label: "ДГД",
        field: "dgd",
      },
      {
        name: "ugd",
        align: "center",
        label: "УГД",
        field: "ugd",
      },
      {
        name: "action",
        align: "left",
        label: "Действия",
        field: "action",
      },
    ];

    const addQuery = async () => {
      if (
        !phoneNumber.value &&
        !bin.value &&
        !ledgerCode.value &&
        !license.value
      ) {
        return $q.notify({
          message: "Необходимо выбрать один из параметров поиска",
          position: "top-right",
          timeout: 2000,
          icon: "error",
        });
      }

      if (phoneNumber.value)
        await router.push({ query: { phone: phoneNumber.value } });
      else if (bin.value)
        await router.push({ query: { bin: bin.value.replace(/[ ]/g, "") } });
      else if (ledgerCode.value)
        await router.push({ query: { code: ledgerCode.value } });
      else if (license.value)
        await router.push({ query: { license: license.value } });
      await fetchUserDetails();
    };

    const fetchUserDetails = async () => {
      const body = {};
      if (phoneNumber.value)
        body.phone = phoneNumber.value.replace(/[()\- ]/g, "");
      else if (bin.value) body.bin = bin.value.replace(/[ ]/g, "");
      else if (ledgerCode.value)
        body.code = ledgerCode.value.replace(/[ ]/g, "");
      else if (license.value) body.license = license.value;

      if (!Object.keys(body).length) return;
      try {
        isLoading.value = true;
        const { data } = await usersApi.getUserDetails(body);
        const phone = localStorage.getItem(LAST_OPENED_USER_PHONE);
        if (phone) {
          const selectedItem = data.find((el) => el.phone_number == phone);
          if (selectedItem) selected.value.push(selectedItem);
        }
        foundUsers.value = data;
      } finally {
        isLoading.value = false;
      }
    };

    const setSelectedUserAndShowActivationModal = (id) => {
      selectedUserId.value = id;
      pickedRateForActivation.value = null;
      isActivationModalVisible.value = true;
    };

    onMounted(async () => {
      if (route.query.phone) phoneNumber.value = route.query.phone;
      else if (route.query.bin) bin.value = route.query.bin;
      else if (route.query.code) ledgerCode.value = route.query.code;
      else if (route.query.license) license.value = route.query.license;
      if (phoneNumber.value || bin.value || ledgerCode.value || license.value)
        await fetchUserDetails();
    });

    const goToUserDetails = (id) => {
      const details = foundUsers.value.find((el) => el.id == id);
      if (details) {
        localStorage.setItem(LAST_OPENED_USER_PHONE, details.phone_number);
      }
      router.push({
        name: "main.wipon-users-details",
        params: { id: id },
      });
    };

    const activateRate = async () => {
      if (!pickedRateForActivation.value?.type) return;
      await subscriptionsApi.activateSub({
        sub_type: pickedRateForActivation.value.type,
        user_id: selectedUserId.value,
      });

      $q.notify({
        message: "Активация тарифа выполнена успешно",
        position: "top-right",
        timeout: 3500,
        icon: "done",
      });

      isActivationModalVisible.value = false;
    };

    return {
      phoneNumber,
      ledgerCode,
      bin,
      license,
      columns,
      foundUsers,
      formattedUsersData,
      isLoading,
      isActivationModalVisible,
      ratesList,
      pickedRateForActivation,
      pagination,
      selected,
      activateRate,
      fetchUserDetails,
      addQuery,
      setSelectedUserAndShowActivationModal,
      goToUserDetails,
    };
  },
});
</script>

<style lang="scss"></style>
