import request from "@/api/request";
const STORES_BASE_URL = "stores";

export const storesApi = {
  detachLicense: (storeId: number) =>
    request.patch(`${STORES_BASE_URL}/detach-license/${storeId}`),
  attachLicense: (storeId: number, licenseId: number) =>
    request.patch(`${STORES_BASE_URL}/attach-license/${storeId}`, {
      licenseId,
    }),
  getStore: (id: string) => request.get(`${STORES_BASE_URL}/${id}`),
  updateStore: (id: string, payload: any) =>
    request.patch(`${STORES_BASE_URL}/${id}`, payload),
  generateUsersBalancePdf: (payload: any) =>
    request.post(`${STORES_BASE_URL}/generate-users-balance-pdf`, payload, {
      responseType: "blob",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    }),
};
