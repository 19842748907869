<template>
  <q-btn
    @click="openEditModal"
    color="primary"
    no-caps
    label="Добавить смс-код"
  />
  <q-dialog v-model="isEditModalVisible">
    <q-card>
      <div class="q-pa-md" style="min-width: 550px">
        <div class="mb-3 bold">
          Введите смс-код для авторизации пользователя:
        </div>
        <q-form @submit.prevent="onSubmit" class="q-gutter-md">
          <q-input v-model.trim="code" maxlength="6" label="СМС-код" />
          <q-card-actions align="center">
            <q-btn
              label="Добавить"
              type="submit"
              color="primary"
              :loading="isLoading"
              :disable="isSubmitDisabled"
            />
          </q-card-actions>
        </q-form>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, inject, computed } from "vue";
import { useQuasar } from "quasar";
import userApi from "@/api/user.api";

const $q = useQuasar();

const user = inject("user");
const isEditModalVisible = ref(false);
const isLoading = ref(false);

const code = ref("");
const isSubmitDisabled = computed(() => {
  return code.value.length !== 6;
});

const onSubmit = async () => {
  if (isLoading.value || code.value.length !== 6) return;
  isLoading.value = true;
  try {
    await userApi.setUsersAuthCode(code.value, user.value.id);
    $q.notify({
      message: "Код успешно сохранен",
      position: "top-right",
      timeout: 5000,
      icon: "done",
    });
    hideEditModal();
  } finally {
    isLoading.value = false;
  }
};

const openEditModal = () => {
  isEditModalVisible.value = true;
};

const hideEditModal = () => {
  isEditModalVisible.value = false;
};
</script>
