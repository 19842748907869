<template>
  <q-dialog v-model="isVisible">
    <q-card class="q-pa-lg" style="max-width: 800px">
      <q-form
        @submit.prevent="searchLicenses"
        class="row align-center q-gutter-md"
      >
        <q-input
          filled
          v-model="number"
          label="Номер лицензии"
          hint="Введите номер лицензии"
          mask="############"
        />

        <q-input
          filled
          v-model="bin"
          label="ИИН/БИН"
          hint="Введите БИН"
          mask="############"
        />

        <q-input
          filled
          v-model="licenseId"
          label="ID лицензии"
          hint="Введите ID лицензии"
          mask="#########"
        />

        <div>
          <q-btn
            label="Поиск"
            type="submit"
            color="primary"
            :disabled="isSearchButtonDisabled"
            :loading="isLoading"
          />
        </div>
      </q-form>

      <div v-show="rows.length" class="q-mt-xl">
        <q-table
          title="Найденные лицензии"
          :rows="rows"
          :columns="columns"
          row-key="name"
          hide-pagination
          hide-no-data
        >
          <template v-slot:body-cell-action="props">
            <q-tr :props="props">
              <q-td key="action" :props="props" class="full-width">
                <q-btn color="grey-7" round flat icon="more_vert">
                  <q-menu cover auto-close>
                    <q-list>
                      <q-item clickable @click="attachLicense(props.row.id)">
                        <q-item-section>Прикрепить лицензию</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, defineComponent, computed } from "vue";
import { objectPropertiesToQuery } from "@/helpers/utils/format.utils";
import { licensesApi } from "@/api/licenses.api";

export default defineComponent({
  name: "AttachLicenseModal",
  setup(props, { emit }) {
    const isVisible = ref(false);
    const isLoading = ref(false);
    const number = ref("");
    const bin = ref("");
    const licenseId = ref("");

    const columns = [
      { name: "id", label: "ID лицензии", field: "id", align: "left" },
      {
        name: "license_number",
        required: true,
        label: "Номер лицензии",
        align: "center",
        field: (row) => row.license_number,
        format: (val) => `${val}`,
      },
      {
        name: "bin",
        align: "center",
        label: "БИН",
        field: "bin",
      },
      { name: "status", label: "Статус", field: "status", align: "center" },
      { name: "source", label: "Источник", field: "source", align: "center" },
      {
        name: "action",
        align: "left",
        label: "Действия",
        field: "action",
      },
    ];

    const rows = ref([]);

    const isSearchButtonDisabled = computed(() => {
      return (
        !number.value.length && !bin.value.length && !licenseId.value.length
      );
    });

    const attachLicense = (id) => {
      emit("attach", id);
    };

    const searchLicenses = async () => {
      if (isSearchButtonDisabled.value) return;
      const filterObject = {};
      if (number.value.length) filterObject.number = number.value;
      if (bin.value.length) filterObject.bin = bin.value;
      if (licenseId.value.length) filterObject.id = licenseId.value;
      const query = objectPropertiesToQuery(filterObject);
      try {
        isLoading.value = true;
        const { data } = await licensesApi.licensesList(query);
        rows.value = data;
      } finally {
        isLoading.value = false;
      }
    };

    return {
      isVisible,
      isLoading,
      number,
      bin,
      licenseId,
      isSearchButtonDisabled,
      columns,
      rows,
      searchLicenses,
      attachLicense,
    };
  },
});
</script>
