<template>
  <q-btn
    @click="openModal"
    color="primary"
    no-caps
    label="Сгенерировать счет на оплату"
  />
  <q-dialog v-model="isModalVisible">
    <q-card>
      <div class="q-pa-md" style="min-width: 500px">
        <q-form @submit.prevent="onUpload" class="flex column q-gutter-md">
          <q-radio
            v-model="selectedRate"
            :val="1"
            label="Смартфон, 1 год, 12 000 ₸"
          />
          <q-radio
            v-model="selectedRate"
            :val="2"
            label="Компьютер, 1 год, 15 000 ₸"
          />
          <q-radio
            v-model="selectedRate"
            :val="3"
            label="ТСД, 1 год, 30 000 ₸"
          />
          <q-card-actions align="center">
            <q-btn
              label="Сгенерировать"
              type="submit"
              color="primary"
              :loading="isLoading"
              :disable="!selectedRate"
            />
          </q-card-actions>
        </q-form>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { defineComponent, ref, inject, reactive, computed } from "vue";
import { useQuasar } from "quasar";
import { downloadBlob } from "@/helpers/utils/download.utils";
import axios from "axios";

export const MOBILE_TYPE = 1;
export const DESKTOP_TYPE = 2;
export const TSD_TYPE = 3;

export const MOBILE_PRICE = 12000;
export const DESKTOP_PRICE = 15000;
export const TSD_PRICE = 30000;

export default defineComponent({
  name: "GenerateInvoicePDFModal",
  setup() {
    const user = inject("user");
    const $q = useQuasar();
    const isModalVisible = ref(false);
    const isLoading = ref(false);
    const selectedRate = ref("");

    const openModal = () => {
      isModalVisible.value = true;
    };

    const rateList = reactive([
      {
        type: MOBILE_TYPE,
        title: "Смартфон, 1 год",
        price: 12000,
        isActive: true,
      },
      {
        type: DESKTOP_TYPE,
        title: "Компьютер, 1 год",
        price: 15000,
        isActive: false,
      },
      {
        type: TSD_TYPE,
        title: "ТСД, 1 год",
        price: 30000,
        isActive: false,
      },
    ]);

    const selectedRateData = computed(() =>
      rateList.find((rate) => rate.type === selectedRate.value)
    );

    const priceFilter = (value) => {
      return Intl.NumberFormat("ru-RU", { style: "decimal" }).format(value);
    };

    const getInvoiceData = () => {
      const date = new Date();
      const formattedDigitDate = new Intl.DateTimeFormat("ru-RU", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: "Asia/Almaty",
      }).format(date);

      const formattedReadableDate = new Intl.DateTimeFormat("ru-RU", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        timeZone: "Asia/Almaty",
      }).format(date);

      const ratePrice = priceFilter(selectedRateData.value.price);
      const rateName =
        selectedRateData.value.type === MOBILE_TYPE
          ? "Мобильное приложение"
          : selectedRateData.value.type === DESKTOP_TYPE
          ? "Компьютер"
          : "ТСД";

      const customerOrganization = user.value.store.buisness_full_legal_name;
      const customerBin = user.value.store.buisness_bin;
      const accountNumber = user.value.ledger.code;

      const invoiceNumber =
        formattedDigitDate.replace(/[., :]/g, "") + user.value.id;

      return {
        number: invoiceNumber,
        numberDate: formattedReadableDate,
        customerBin,
        customerOrganization,
        accountNumber,
        ratePrice,
        rateName,
        rateLetterPrice: sum_letters(selectedRateData.value.price),
      };
    };

    const onUpload = async () => {
      try {
        isLoading.value = true;
        const invoiceData = getInvoiceData();
        const response = await axios.post(
          "https://api.cabinet.wipon.pro/v1/pdf/generate-invoice",
          invoiceData,
          {
            responseType: "blob",
          }
        );
        const fileName = `Wipon_invoice_${invoiceData.number}.pdf`;
        await downloadBlob(response, fileName);
        $q.notify({
          message: "Счет на оплату успешно сгенерирован",
          position: "top-right",
          timeout: 3500,
          icon: "done",
        });
        isModalVisible.value = false;
      } finally {
        isLoading.value = false;
      }
    };

    const sum_letters = (num) => {
      num = num.toString();
      return razUp(numLetters(num, 1));
    };

    const razUp = (e) => {
      return e[1].toUpperCase() + e.substring(2);
    };

    const numLetters = (k, d) => {
      let i = "",
        e = [
          [
            "",
            "тысяч",
            "миллион",
            "миллиард",
            "триллион",
            "квадриллион",
            "квинтиллион",
            "секстиллион",
            "септиллион",
            "октиллион",
            "нониллион",
            "дециллион",
          ],
          ["а", "и", ""],
          ["", "а", "ов"],
        ];
      if (k == "" || k == "0") return " ноль"; // 0
      k = k.split(/(?=(?:\d{3})+$)/); // разбить число в массив с трёхзначными числами
      if (k[0].length == 1) k[0] = "00" + k[0];
      if (k[0].length == 2) k[0] = "0" + k[0];
      for (let j = k.length - 1; j >= 0; j--) {
        if (k[j] != "000") {
          i =
            (((d && j == k.length - 1) || j == k.length - 2) &&
            (k[j][2] == "1" || k[j][2] == "2")
              ? t(k[j], 1)
              : t(k[j])) +
            declOfNum(
              k[j],
              e[0][k.length - 1 - j],
              j == k.length - 2 ? e[1] : e[2]
            ) +
            i;
        }
      }
      function t(k, d) {
        // преобразовать трёхзначные числа
        let e = [
          [
            "",
            " один",
            " два",
            " три",
            " четыре",
            " пять",
            " шесть",
            " семь",
            " восемь",
            " девять",
          ],
          [
            " десять",
            " одиннадцать",
            " двенадцать",
            " тринадцать",
            " четырнадцать",
            " пятнадцать",
            " шестнадцать",
            " семнадцать",
            " восемнадцать",
            " девятнадцать",
          ],
          [
            "",
            "",
            " двадцать",
            " тридцать",
            " сорок",
            " пятьдесят",
            " шестьдесят",
            " семьдесят",
            " восемьдесят",
            " девяносто",
          ],
          [
            "",
            " сто",
            " двести",
            " триста",
            " четыреста",
            " пятьсот",
            " шестьсот",
            " семьсот",
            " восемьсот",
            " девятьсот",
          ],
          ["", " одна", " две"],
        ];
        return (
          e[3][k[0]] +
          (k[1] == 1 ? e[1][k[2]] : e[2][k[1]] + (d ? e[4][k[2]] : e[0][k[2]]))
        );
      }
      return i;
    };

    const declOfNum = (n, t, o) => {
      const k = [2, 0, 1, 1, 1, 2, 2, 2, 2, 2];
      return t == ""
        ? ""
        : " " + t + (n[n.length - 2] == "1" ? o[2] : o[k[n[n.length - 1]]]);
    };

    return {
      selectedRate,
      isModalVisible,
      isLoading,
      openModal,
      onUpload,
    };
  },
});
</script>
