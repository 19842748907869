<template>
  <q-btn
    @click="openEditModal"
    color="primary"
    no-caps
    label="Изменить рег. данные"
  />
  <q-dialog v-model="isEditModalVisible">
    <q-card>
      <div class="q-pa-md" style="min-width: 550px">
        <div class="mb-3 bold">
          ЛИЦЕНЗИЯ НА ХРАНЕНИЕ И РЕАЛИЗАЦИЮ АЛКОГОЛЬНОЙ ПРОДУКЦИИ:
        </div>
        <q-form @submit.prevent="onSubmit" class="q-gutter-md">
          <q-input
            v-model.trim="store.buisness_full_legal_name"
            label="Название лицензиата"
          />
          <q-input
            v-model.trim="store.buisness_bin"
            maxlength="12"
            label="БИН/ИИН лицензиата"
          />
          <q-input v-model.trim="store.license_number" label="Номер лицензии" />
          <q-input
            v-model.trim="store.buisness_store_name"
            label="Название объекта осуществления деятельности"
          />
          <q-input
            v-model.trim="store.buisness_store_address"
            label="Адрес объекта осуществления деятельности"
          />
          <q-select
            filled
            v-model="store.buisness_dgd_id"
            :options="dgdList"
            label="ДГД"
            emit-value
            map-options
          />
          <q-select
            filled
            v-model="store.buisness_ugd_id"
            :options="filteredUgdList"
            label="УГД"
            emit-value
            map-options
          />
          <hr />
          <div class="mb-3 bold">РЕКВИЗИТЫ ПЛАТЕЛЬЩИКА:</div>
          <q-input
            v-model.trim="store.payer_name"
            label="Название организации"
          />
          <q-input
            v-model.trim="store.payer_bin"
            maxlength="12"
            label="БИН/ИИН"
          />
          <q-input
            v-model.trim="store.payer_address"
            label="Юридический адрес"
          />
          <q-input
            v-model.trim="store.payer_postal_address"
            label="Почтовый адрес"
          />
          <q-input v-model.trim="store.payer_email" label="Email организации" />
          <q-card-actions align="center">
            <q-btn
              label="Изменить"
              type="submit"
              color="primary"
              :loading="isLoading"
            />
          </q-card-actions>
        </q-form>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, inject, onMounted, reactive, computed, defineEmits } from "vue";
import { useQuasar } from "quasar";
import { dgdApi } from "@/api/dgd.api";
import { storesApi } from "@/api/stores.api";

const emit = defineEmits(["refetch"]);

const user = inject("user");
const $q = useQuasar();
const isEditModalVisible = ref(false);
const isLoading = ref(false);

const dgdList = ref([]);
const ugdList = ref([]);

const store = ref({
  id: "",
  buisness_bin: "",
  buisness_full_legal_name: "",
  buisness_store_address: "",
  license_number: "",
  buisness_store_name: "",
  payer_postal_address: "",
  buisness_dgd_id: null,
  buisness_ugd_id: null,
  payer_email: "",
  payer_bin: "",
  payer_address: "",
  payer_name: "",
});

const filteredUgdList = computed(() => {
  if (!store.value.buisness_dgd_id) return [];
  return ugdList.value.filter((el) => el.dgd_id == store.value.buisness_dgd_id);
});

const onSubmit = async () => {
  if (isLoading.value) return;
  isLoading.value = true;
  try {
    await storesApi.updateStore(user.value.store.id, store.value);
    $q.notify({
      message: "Данные успешно изменены",
      position: "top-right",
      timeout: 5000,
      icon: "done",
    });
    emit("refetch");
    hideEditModal();
  } finally {
    isLoading.value = false;
  }
};

const openEditModal = () => {
  isEditModalVisible.value = true;
};

const hideEditModal = () => {
  isEditModalVisible.value = false;
};

const fetchStore = async () => {
  const { data: storeData } = await storesApi.getStore(user.value.store.id);
  store.value = storeData;
};

const fetchDgdList = async () => {
  const { data } = await dgdApi.fetchDgds();
  if (data && Array.isArray(data)) {
    data.forEach((el) => {
      el.label = el.name_ru;
      el.value = el.id;
    });
    dgdList.value = data;
  }
};

const fetchUgdList = async () => {
  const { data } = await dgdApi.fetchUgds();
  if (data && Array.isArray(data)) {
    data.forEach((el) => {
      el.label = el.name_ru;
      el.value = el.id;
    });
    ugdList.value = data;
  }
};

onMounted(async () => {
  await fetchDgdList();
  await fetchUgdList();
  await fetchStore();
});
</script>
