
import { defineComponent } from "vue";
import SectionCard from "@/components/common/SectionCard.vue";
import subscriptionsApi from "@/api/subscriptions.api";

export default defineComponent({
  name: "MainPage",
  components: {
    SectionCard,
  },
  data: () => ({
    isActivationModalVisible: false,
    isLoading: false,
  }),
  methods: {
    toReportsPage() {
      this.$router.push({ name: "main.reports" });
    },
    toUsersPage() {
      this.$router.push({ name: "main.wipon-users" });
    },
    toTransactionsPage() {
      this.$router.push({ name: "main.users-transactions" });
    },
    toFeedbackMessagesPage() {
      this.$router.push({ name: "main.feedback-messages" });
    },
    toTransferMoneyPage() {
      this.$router.push({ name: "main.transfer-money" });
    },
    toLicensesPage() {
      this.$router.push({ name: "main.licenses" });
    },
    showActivationSubscriptionsModal() {
      this.isActivationModalVisible = true;
    },
    async startActivationProcess() {
      this.isLoading = true;
      try {
        subscriptionsApi.activateAllSubs();
        this.isActivationModalVisible = false;
        alert("Процесс активации запущена");
      } finally {
        this.isLoading = false;
      }
    },
  },
});
