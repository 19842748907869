<template>
  <q-btn
    @click="openEditModal"
    color="primary"
    no-caps
    label="Изменить номер телефона"
  />
  <q-dialog v-model="isEditModalVisible">
    <q-card>
      <div class="q-pa-md" style="min-width: 500px">
        <q-form @submit.prevent="onUpload" class="q-gutter-md">
          <q-input
            v-model.trim="phone"
            label="Телефон"
            :error="isExists"
            error-message="Введенный номер уже занят другим пользователем"
          />
          <q-card-actions align="center">
            <q-btn
              label="Изменить"
              type="submit"
              color="primary"
              :loading="isLoading"
            />
          </q-card-actions>
        </q-form>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { defineComponent, ref, inject } from "vue";
import usersApi from "@/api/user.api";
import { useQuasar } from "quasar";

export default defineComponent({
  name: "EditModal",
  setup() {
    const user = inject("user");
    const $q = useQuasar();
    const isEditModalVisible = ref(false);
    const isLoading = ref(false);
    const isExists = ref(false);
    const phone = ref("");

    const openEditModal = () => {
      isEditModalVisible.value = true;
      phone.value = user.value?.phone_number;
    };
    const onUpload = async () => {
      const phoneFormatted = phone.value.trim();

      if (!phoneFormatted.length) return;
      isLoading.value = true;
      isExists.value = false;

      try {
        const payload = {
          phone_number: phoneFormatted,
        };
        const { data } = await usersApi.update(user.value.id, payload);
        if (data.is_exists) {
          return (isExists.value = true);
        }
        user.value = data;
        $q.notify({
          message: "Номер телефона успешно изменена",
          position: "top-right",
          timeout: 3500,
          icon: "done",
        });
        isEditModalVisible.value = false;
      } finally {
        isLoading.value = false;
      }
    };

    return {
      isEditModalVisible,
      isLoading,
      isExists,
      openEditModal,
      onUpload,
      phone,
    };
  },
});
</script>
