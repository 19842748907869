<template>
  <section class="section">
    <h4 class="section__title section__title_centered">
      Детали пользователя Wipon
    </h4>
    <svg
      @click="back"
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="#343a40"
      class="bi bi-arrow-left mb-1"
      viewBox="0 0 16 16"
      style="cursor: pointer"
    >
      <path
        fill-rule="evenodd"
        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
      />
    </svg>
    <div class="user-info">
      <div class="q-gutter-md" style="min-width: 250px; max-width: 450px">
        <template v-if="userDetails.store">
          <q-field
            v-for="col in userDetailsColumns"
            :key="col.label"
            :label="col.label"
            stack-label
          >
            <template v-slot:control>
              <div class="self-center full-width no-outline">
                {{ col.value }}
              </div>
            </template>
          </q-field>
        </template>
        <div v-else class="text-h5">
          Пользователь не заполнил данные о торговой точке
        </div>
      </div>
      <div class="q-gutter-md">
        <q-list bordered class="rounded-borders" style="min-width: 250px">
          <q-item-label class="flex justify-between" header>
            <span class="flex" style="align-items: center"
              >Информация о лицензии</span
            >
            <!--            <q-icon-->
            <!--              class="q-ml-auto"-->
            <!--              name="info"-->
            <!--              :color="isLicenseValid ? 'green' : 'gray'"-->
            <!--            />-->
            <q-btn color="grey-7" round flat icon="edit">
              <q-menu class="q-ml-auto" cover auto-close>
                <q-list>
                  <q-item
                    v-if="userDetails.store.ugd_licenses"
                    @click="detachLicense"
                    clickable
                  >
                    <q-item-section>Отвязать лицензию</q-item-section>
                  </q-item>
                  <q-item
                    v-if="!userDetails.store.ugd_licenses"
                    @click="showAttachLicenseModal"
                    clickable
                  >
                    <q-item-section>Привязать лицензию</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </q-item-label>

          <q-item>
            <q-item-section>
              <q-item-label> Номер лицензии </q-item-label>
            </q-item-section>
            <q-item-section side>
              <span class="text-weight-bold text-dark">
                {{ usersLicenseNumber }}</span
              >
            </q-item-section>
          </q-item>

          <q-separator />

          <q-item>
            <q-item-section>
              <q-item-label>Статус</q-item-label>
            </q-item-section>
            <q-item-section side>
              <span
                class="text-weight-bold"
                :class="{ 'text-green': isLicenseValid }"
              >
                {{ isLicenseValid ? "Действительный" : "Недействительный" }}
              </span>
            </q-item-section>
          </q-item>
        </q-list>

        <q-list bordered class="rounded-borders" style="min-width: 250px">
          <q-item-label class="flex justify-between" header
            ><span>Баланс:</span>
            <span class="text-weight-bold text-black"
              >{{ totalBalance }} ₸</span
            >
          </q-item-label>
          <template
            v-for="(acc, idx) in userDetails.ledger.accounts"
            :key="acc.id"
          >
            <q-item>
              <q-item-section>
                <q-item-label> {{ acc.provider }} </q-item-label>
              </q-item-section>
              <q-item-section side>
                <span class="text-weight-bold text-dark">
                  {{ acc.balance }} ₸</span
                >
              </q-item-section>
            </q-item>
            <q-separator v-if="idx != userDetails.ledger.accounts.length - 1" />
          </template>
        </q-list>
      </div>
      <div class="q-gutter-md">
        <q-list bordered class="rounded-borders" style="min-width: 200px">
          <q-item-label header>
            {{
              formattedSubscriptionsHistory.length
                ? "Подписки:"
                : "Отсутствует подписка"
            }}
          </q-item-label>
          <template
            v-for="(sub, idx) in formattedSubscriptionsHistory"
            :key="sub.activation_date"
          >
            <q-item>
              <q-item-section>
                <q-item-label
                  :class="{
                    'text-weight-bold': sub.is_active,
                    'text-green': sub.is_active,
                  }"
                  >{{ sub.name }}</q-item-label
                >
                <q-item-label caption
                  >Дата активации: {{ sub.activation_date }} <br />
                  Дата истечения: {{ sub.expiration_date }}</q-item-label
                >
              </q-item-section>

              <q-item-section side top>
                <q-icon name="info" :color="sub.is_active ? 'green' : 'gray'" />
              </q-item-section>
            </q-item>
            <q-separator
              v-if="idx != formattedSubscriptionsHistory.length - 1"
              spaced
              inset
            />
          </template>
        </q-list>
      </div>
      <div class="q-gutter-md">
        <q-btn
          @click="isDepositModalVisible = true"
          color="primary"
          no-caps
          label="Пополнить счет"
        />
        <q-btn
          :to="{
            name: 'main.users-transactions',
            query: { code: userDetails.ledger.code },
          }"
          target="_blank"
          color="primary"
          no-caps
          label="Список транзакции"
        />
        <q-btn
          @click="openActivationModal"
          color="primary"
          no-caps
          label="Активировать тариф"
        />
        <q-btn
          @click="openNotificationModal"
          color="primary"
          no-caps
          label="Пуш уведомление"
        />
        <q-btn
          @click="openScanDetailsModal"
          color="primary"
          no-caps
          label="Детализация УКМ"
        />
        <q-btn
          @click="showUsersAuthCode"
          color="primary"
          no-caps
          label="Смс-код"
          :loading="isSmsCodeLoading"
        />
        <set-users-auth-code-modal />
        <edit-modal v-if="userDetails.id" />
        <generate-invoice-p-d-f-modal v-if="userDetails.id" />
        <user-details-edit-modal
          v-if="userDetails.id"
          @refetch="fetchUserDetails"
        />
      </div>
    </div>

    <q-dialog v-model="isActivationModalVisible">
      <q-card>
        <div class="q-pa-md" style="max-width: 400px; min-width: 400px">
          <q-form @submit="activateRate" class="q-gutter-md">
            <q-select
              filled
              v-model="pickedRateForActivation"
              :options="ratesList"
              label="Выберите тариф для активации"
              lazy-rules
              :rules="[(val) => val || 'Поле обязательное']"
            />
            <div class="q-mt-lg">
              <q-btn
                label="Активировать"
                type="submit"
                color="primary"
                :loading="isLoading"
              />
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>

    <q-dialog v-model="isDepositModalVisible">
      <q-card>
        <div class="q-pa-md" style="max-width: 400px; min-width: 400px">
          <q-form @submit="makeDeposit" class="q-gutter-md">
            <q-input
              filled
              v-model="sum"
              mask="#.##"
              fill-mask="0"
              reverse-fill-mask
              label="Сумма пополнения *"
              lazy-rules
              :rules="[(val) => (val && val > 0) || 'Поле обязательное']"
            />
            <q-input v-model="depositDate" filled type="date" />
            <div class="q-mt-lg">
              <q-btn
                label="Пополнить"
                type="submit"
                color="primary"
                :loading="isLoading"
              />
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>

    <q-dialog v-model="isNotificationModalOpen">
      <q-card>
        <div class="q-pa-md" style="max-width: 400px; min-width: 400px">
          <q-form @submit="sendNotification" class="q-gutter-md">
            <q-input
              filled
              type="textarea"
              v-model="notificationMsg"
              label="Сообщение пользователю *"
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || 'Поле обязательное']"
            />
            <q-btn
              label="Отправить"
              type="submit"
              color="primary"
              :loading="isLoading"
            />
          </q-form>
        </div>
      </q-card>
    </q-dialog>

    <q-dialog v-model="isScanDetailsModalVisible">
      <q-card>
        <div class="q-pa-md" style="max-width: 500px; min-width: 500px">
          <q-form
            @submit.prevent="generateUserScansReport"
            class="q-gutter-md flex"
            style="flex-direction: column"
          >
            <CustomDatePicker @datePicked="setDates" />
            <span
              v-show="isSelectedScanDatesMoreThanMonth"
              class="text-red-6 bold"
            >
              Максимальный интервал временного диапазона - 1 месяц
            </span>
            <div
              class="q-mt-lg flex justify-center align-center"
              style="margin-top: 240px"
            >
              <q-btn
                label="Сгенерировать excel"
                type="submit"
                color="primary"
                :loading="isLoading"
                :disable="isUserScansGenerationButtonDisabled"
              />
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>

    <attach-license-modal ref="attachLicenseModalRef" @attach="attachLicense" />
  </section>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  nextTick,
  provide,
  ref,
} from "vue";
import {
  DESKTOP_PRICE,
  DESKTOP_TYPE,
  MOBILE_PRICE,
  MOBILE_TYPE,
  TSD_PRICE,
  TSD_TYPE,
} from "@/config/rates.config";
import EditModal from "./components/EditModal.vue";
import subscriptionsApi from "@/api/subscriptions.api";
import usersApi from "@/api/user.api";
import transactionsApi from "@/api/transactions.api";
import { useQuasar } from "quasar";
import { useRoute, useRouter } from "vue-router";
import { formatBlobErrorToMsg } from "@/helpers/utils/format.utils";
import reportsApi from "@/api/reports.api";
import { downloadBlob } from "@/helpers/utils/download.utils";
import CustomDatePicker from "@/components/common/DatePicker";
import { storesApi } from "@/api/stores.api";
import AttachLicenseModal from "@/views/WiponUsers/components/AttachLicenseModal";
import { diffMoreThanMonth } from "@/helpers/utils/helper.utils";
import UserDetailsEditModal from "@/views/WiponUsers/components/UserDetailsEditModa";
import SetUsersAuthCodeModal from "@/views/WiponUsers/components/SetUsersAuthCodeModal.vue";
import GenerateInvoicePDFModal from "@/views/WiponUsers/components/GenerateInvoicePDFModal.vue";
const FROM_ROUTE_STORAGE_KEY = "from_route";
const VALID_LICENSE = "Действительный";

export default defineComponent({
  name: "WiponUserDetailsPage",
  components: {
    GenerateInvoicePDFModal,
    SetUsersAuthCodeModal,
    UserDetailsEditModal,
    AttachLicenseModal,
    CustomDatePicker,
    EditModal,
  },
  beforeRouteEnter(to, from, next) {
    if (from)
      sessionStorage.setItem(
        FROM_ROUTE_STORAGE_KEY,
        JSON.stringify({ name: from.name, query: from.query })
      );
    next();
  },
  setup() {
    const $q = useQuasar();
    const route = useRoute();
    const router = useRouter();
    const sum = ref("");
    const depositDate = ref("");
    const attachLicenseModalRef = ref(null);
    const userDetailsEditModalRef = ref(null);
    const scanDate = ref({
      from: "",
      to: "",
    });
    const isDepositModalVisible = ref(false);
    const isScanDetailsModalVisible = ref(false);

    const backRouteData = ref(null);

    const ratesList = ref([
      { id: 1, type: MOBILE_TYPE, price: MOBILE_PRICE, label: "Смартфон" },
      { id: 2, type: DESKTOP_TYPE, price: DESKTOP_PRICE, label: "Десктоп" },
      { id: 3, type: TSD_TYPE, price: TSD_PRICE, label: "ТСД" },
    ]);
    const pickedRateForActivation = ref(null);
    const isActivationModalVisible = ref(false);

    const notificationMsg = ref("");
    const isNotificationModalOpen = ref(false);

    const isLoading = ref(false);
    const isSmsCodeLoading = ref(false);
    const isUserFound = ref(false);
    const userDetails = ref({
      id: "",
      phone_number: "",
      ledger: { code: "", accounts: [] },
      devices: [],
      subscriptions: [],
      store: {
        id: "",
        buisness_bin: "", // БИН
        buisness_full_legal_name: "", // Название организации
        buisness_store_address: "", // Адрес объекта деятельности
        license_number: "", // Номер лицензии
        buisness_store_name: "", // Название объекта деятельности
        ugd_licenses: null,
      },
    });

    const isUserScansGenerationButtonDisabled = computed(() => {
      if (!scanDate.value.from || !scanDate.value.to) return true;
      return isSelectedScanDatesMoreThanMonth.value;
    });

    const isSelectedScanDatesMoreThanMonth = computed(() => {
      if (!scanDate.value.from || !scanDate.value.to) return false;
      const fromDate = new Date(scanDate.value.from);
      const toDate = new Date(scanDate.value.to);
      return diffMoreThanMonth(fromDate, toDate);
    });

    const totalBalance = computed(() => {
      if (!userDetails.value.ledger.accounts.length) return 0;
      const total = userDetails.value.ledger.accounts.reduce(
        (accumulate, el) => accumulate + Number(el.balance),
        0
      );
      return new Intl.NumberFormat("ru-RU", { currency: "KZT" }).format(total);
    });

    const isLicenseValid = computed(() => {
      if (!userDetails.value.store.ugd_licenses) return false;
      return userDetails.value.store.ugd_licenses.status === VALID_LICENSE;
    });

    const setDates = (pickedDates) => {
      scanDate.value.from = pickedDates.start.toISOString().split("T")[0];
      scanDate.value.to = pickedDates.end.toISOString().split("T")[0];
    };

    const openActivationModal = () => {
      pickedRateForActivation.value = null;
      isActivationModalVisible.value = true;
    };

    const openScanDetailsModal = () => {
      isScanDetailsModalVisible.value = true;
    };

    const openNotificationModal = () => {
      notificationMsg.value = "";
      isNotificationModalOpen.value = true;
    };

    const showAttachLicenseModal = () => {
      if (!userDetails.value.store?.id) return;
      if (userDetails.value.store.ugd_licenses?.license_number) return;
      attachLicenseModalRef.value.isVisible = true;
    };

    const attachLicense = (id) => {
      if (!userDetails.value.store?.id) return;
      $q.dialog({
        title: "Привязать лицензию",
        message: `Вы действительно хотите привязать лицензию с ID ${id} на текущего пользователя?`,
        cancel: true,
        persistent: true,
      }).onOk(async () => {
        await storesApi.attachLicense(userDetails.value.store.id, id);
        $q.notify({
          message: "Лицензия успешно привязана к пользователю",
          position: "top-right",
          timeout: 3500,
          icon: "done",
        });
        await fetchUserDetails();
        attachLicenseModalRef.value.isVisible = false;
      });
    };

    const detachLicense = () => {
      if (!userDetails.value.store.ugd_licenses?.license_number) return;
      if (!userDetails.value.store?.id) return;
      $q.dialog({
        title: "Отвязать лицензию",
        message: `Вы действительно хотите отвязать лицензию ${userDetails.value.store.ugd_licenses?.license_number} от текущего пользователя?`,
        cancel: true,
        persistent: true,
      }).onOk(async () => {
        await storesApi.detachLicense(userDetails.value.store.id);
        $q.notify({
          message: "Лицензия успешно отвязана от пользователя",
          position: "top-right",
          timeout: 3500,
          icon: "done",
        });
        await fetchUserDetails();
      });
    };

    const activateRate = async () => {
      if (!pickedRateForActivation.value.type) return;
      await subscriptionsApi.activateSub({
        sub_type: pickedRateForActivation.value.type,
        user_id: userDetails.value.id,
      });

      await fetchUserDetails();

      $q.notify({
        message: "Активация тарифа выполнена успешно",
        position: "top-right",
        timeout: 3500,
        icon: "done",
      });

      isActivationModalVisible.value = false;
    };

    const sendNotification = async () => {
      isLoading.value = true;
      try {
        await usersApi.sendNotification({
          user_id: userDetails.value.id,
          text: notificationMsg.value,
        });
        $q.notify({
          message: "Уведомление успешно отправлено",
          position: "top-right",
          timeout: 3500,
          icon: "done",
        });
        isNotificationModalOpen.value = false;
      } finally {
        isLoading.value = false;
      }
    };

    const generateUserScansReport = async () => {
      isLoading.value = true;
      try {
        const payload = {
          user_id: userDetails.value.id,
        };
        if (scanDate.value.from && scanDate.value.to) {
          payload.from = scanDate.value.from;
          payload.to = scanDate.value.to;
        }
        const response = await reportsApi.getUserScansReport(payload);
        downloadBlob(response, "wipon-scans");
      } catch (e) {
        await formatBlobErrorToMsg(e);
      } finally {
        isLoading.value = false;
        isScanDetailsModalVisible.value = false;
      }
    };

    const makeDeposit = async () => {
      try {
        isLoading.value = true;
        const payload = {
          user_id: userDetails.value.id,
          provider: "bank",
          amount: sum.value,
          timestamp: depositDate.value
            ? new Date(depositDate.value).valueOf()
            : null,
        };
        await transactionsApi.makeDeposit(payload);
        sum.value = "";
        isDepositModalVisible.value = false;
        $q.notify({
          message: `Пополнение на сумму ${sum.value} успешно выполнено`,
          position: "top-right",
          timeout: 3500,
          icon: "done",
        });
      } finally {
        isLoading.value = false;
      }
    };

    const showUsersAuthCode = async () => {
      try {
        isSmsCodeLoading.value = true;
        const { data } = await usersApi.getUsersAuthCode(userDetails.value.id);
        let message = "";
        if (!data)
          message =
            "В базе отсутствует акутальный смс код данного пользователя";
        else message = `Актуальный смс код: ${data.code}`;
        $q.dialog({
          title: "Смс код",
          message,
          persistent: true,
        });
      } finally {
        isSmsCodeLoading.value = false;
      }
    };

    const formatDate = (date) => {
      return new Intl.DateTimeFormat("ru-RU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(date);
    };

    const formattedSubscriptionsHistory = computed(() => {
      const result = [];
      const mobileSubs = userDetails.value.subscriptions;
      const tsdAndDesktopSubs = userDetails.value.devices;
      if (!mobileSubs.length && !tsdAndDesktopSubs.length) return result;

      mobileSubs.forEach((mobile) => {
        const activationDate = new Date(mobile.created_at);
        const expirationDate = new Date(mobile.expires_at);
        result.push({
          name: "Мобильное приложение",
          activation_date: formatDate(activationDate),
          expiration_date: formatDate(expirationDate),
          is_active: mobile.is_active,
        });
      });

      tsdAndDesktopSubs.forEach((sub) => {
        if (sub.user_id) {
          const activationDate = new Date(sub.updated_at);
          const notFormattedDate = new Date(sub.updated_at);
          const expirationDate = new Date(
            notFormattedDate.setFullYear(notFormattedDate.getFullYear() + 1)
          );
          result.push({
            name: sub.application_type === "desktop" ? "Десктоп" : "ТСД",
            activation_date: formatDate(activationDate),
            expiration_date: formatDate(expirationDate),
            is_active: true,
          });
        } else {
          const notFormattedDate = new Date(sub.updated_at);
          const activationDate = new Date(
            notFormattedDate.setFullYear(notFormattedDate.getFullYear() - 1)
          );
          const expirationDate = new Date(sub.updated_at);
          result.push({
            name: sub.application_type === "desktop" ? "Десктоп" : "ТСД",
            activation_date: formatDate(activationDate),
            expiration_date: formatDate(expirationDate),
            is_active: false,
          });
        }
      });

      return result.sort((a) => (a.is_active ? -1 : 1));
    });

    const userDetailsColumns = computed(() => {
      return [
        {
          label: "Номер телефона",
          value: userDetails.value.phone_number,
        },
        {
          label: "Лицевой счет",
          value: userDetails.value.ledger.code,
        },
        {
          label: "БИН/ИИН",
          value: userDetails.value.store.buisness_bin,
        },
        {
          label: "Название организации",
          value: userDetails.value.store.buisness_full_legal_name,
        },
        {
          label: "Название объекта деятельности",
          value: userDetails.value.store.buisness_store_name,
        },
        {
          label: "Адрес объекта деятельности",
          value: userDetails.value.store.buisness_store_address,
        },
        // {
        //   label: "Номер лицензии",
        //   value: userDetails.value.store.license_number,
        // },
      ];
    });

    const usersLicenseNumber = computed(() => {
      if (!userDetails.value.store.ugd_licenses?.license_number) return "-";
      return userDetails.value.store.ugd_licenses.license_number;
    });

    const fetchUserDetails = async () => {
      // await router.push({ name: "main.wipon-users" });
      // return alert("Пользователь не найден");
      try {
        isLoading.value = true;
        const id = String(route.params.id);
        const { data } = await usersApi.getUserDetailsById(id);
        if (data) {
          userDetails.value = data;
          await nextTick();
          isUserFound.value = true;
        } else {
          return alert("Пользователь не найден");
        }
      } catch (e) {
        await router.push({ name: "main.wipon-users" });
        return alert(
          "Произошла ошибка при попытке получить детали пользователя"
        );
      } finally {
        isLoading.value = false;
      }
    };

    const back = () => {
      // const routeData = { name: "main.wipon-users" };
      // if (!backRouteData.value) return router.push(routeData);
      //
      // const queries = backRouteData.value?.query || {};
      // if (!Object.keys(queries).length) return router.push(routeData);
      // router.push(backRouteData.value);
      router.back();
    };

    onMounted(async () => {
      const fromRoute = JSON.parse(
        sessionStorage.getItem(FROM_ROUTE_STORAGE_KEY)
      );
      if (fromRoute) {
        backRouteData.value = fromRoute;
      }
      await fetchUserDetails();
    });

    provide("user", userDetails);

    return {
      attachLicenseModalRef,
      sum,
      depositDate,
      userDetails,
      userDetailsColumns,
      isUserFound,
      isLoading,
      isSmsCodeLoading,
      isDepositModalVisible,
      isNotificationModalOpen,
      notificationMsg,
      formattedSubscriptionsHistory,
      ratesList,
      pickedRateForActivation,
      isActivationModalVisible,
      totalBalance,
      isScanDetailsModalVisible,
      scanDate,
      isLicenseValid,
      usersLicenseNumber,
      isUserScansGenerationButtonDisabled,
      isSelectedScanDatesMoreThanMonth,
      fetchUserDetails,
      detachLicense,
      showAttachLicenseModal,
      makeDeposit,
      activateRate,
      openActivationModal,
      openNotificationModal,
      sendNotification,
      showUsersAuthCode,
      openScanDetailsModal,
      back,
      setDates,
      attachLicense,
      generateUserScansReport,
    };
  },
});
</script>
